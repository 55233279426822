import React, {
  useState,
  useCallback,
  useEffect,
  useMemo
} from "react";
import { useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import {
  guid,
  NOOP
} from "Utils";
import {
  useLocation
} from "react-router";

const useTabs = ({
  tabs,
  changeActiveTab = NOOP,  // No Operation, the default value for changeActiveTab
  isParams = true
}: any) => {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const { pathname } = useLocation()


  const _tabs = useMemo(() => {
    return tabs.map((t: any) => ({
      ...t,
      guid: guid()
    }))
  }, [tabs])

  const [value, setValue] = useState(() => {
    const storedTab = localStorage.getItem('activeTab'); // Stored tab index
    if (storedTab) {
      return parseInt(storedTab, 10);  // Use stored tab index if available
    }

    const tabId = searchParams?.get('tab')
    if (!tabId) return 0
    const _tab = _tabs.find((x: any) => x.id === tabId)
    return !_tab ? 0 : _tab.value
  });

  useEffect(() => {
    if (!isParams) return
    const tab = _tabs.find((x: any) => x.value === value)
    if (!tab) return
    setValue(tab.value)
    changeActiveTab(tab.value)
    navigate(`${pathname}?${createSearchParams({
      tab: tab.id
    })}`);
  }, [value, _tabs, navigate, pathname, isParams, changeActiveTab])

  const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    changeActiveTab(newValue)
    console.log("Tab changed. Page: ", localStorage.getItem('page'), " Rows PP: ", localStorage.getItem('rowsPerPage'))
    localStorage.setItem('activeTab', newValue.toString());  // Store the active tab index in localStorage
    //localStorage.setItem('page', '0');  // Reset page to 0  // BAD CODE
    //localStorage.setItem('rowsPerPage', '25'); // Reset rows per page
  }, [setValue, changeActiveTab])

  const data = useMemo(() => ({
    activeTab: value,
    tabs: _tabs,
    handleChange
  }), [value, _tabs, handleChange])

  return data
}




const withTabs = (Component: React.FC) => (props: any) => {
  const { activeTab, tabs, handleChange } = useTabs(props);
  return <Component {...props} tabs={tabs} activeTab={activeTab} handleChange={handleChange} />
}

export default withTabs
