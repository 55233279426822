import React, { useMemo, useState, useRef, useEffect } from 'react'
import {
    AmazonLinkContainer,
    AmazonLinkUS,
    AmazonLinkCA,
    EditButton,
    EditForm
} from "pages/Legacy/Marketing/components/AmazonLink/style";
import Flag from "react-world-flags";
import API from "api";

const AmazonLink = ({ value, className, isCa = true, row, canEdit = false }: { value: string, className?: string, isCa?: boolean, row?: any, canEdit?: boolean }) => {

    // const [asin, link] = useMemo(() => [
    //     value,
    //     !/^[A-Za-z]+$/.test(value) && value !== 'N/A' ? `https://www.amazon.com/dp/${value}` : ''
    // ], [value])

    const [isInvalidAsinUS, isInvalidAsinCA, epid] = useMemo(() => [
        row?.original?.isInvalidAsinUS,
        row?.original?.isInvalidAsinCA,
        row?.original?.ebayEpid
    ], [row])

    const [asin, setAsin] = useState(value);  // Manage asin directly in the state
    const [link, setLink] = useState(() => !/^[A-Za-z]+$/.test(value) && value !== 'N/A' ? `https://www.amazon.com/dp/${value}` : '');  // Directly manage link state
    const [editing, setEditing] = useState(false);
    const [newAsin, setNewAsin] = useState(value || "")

    useEffect(() => {
        if (value && value !== asin) {
            setAsin(value);  // Update the ASIN state if value changes
            setLink(`https://www.amazon.com/dp/${value}`);  // Update the link state
        }
    }, [value, asin]);

    const editFormRef = useRef<HTMLDivElement | null>(null);

    // Handle clicks outside of EditForm
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (editFormRef.current && !editFormRef.current.contains(event.target as Node)) {
                setEditing(false); // Close the editor if click is outside
                setNewAsin("")  // Clear the new asin value
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleChange = (e: any) => {
        setNewAsin(e.target.value);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        //VALIDATE FORM
        console.log("Let's get you a new ASIN! ", newAsin, " Current epid: ", epid)
        // Validate input
        let asinToAdd = newAsin.trim(); // Trim whitespace
        if (asinToAdd.length !== 10 || !/^[a-z0-9]+$/i.test(asinToAdd)) { // An ASIN should be a ten letter alphanumeric id
            console.log("THIS IS NOT A VALID ASIN! TRY AGAIN!")  // Change this to something the user can see. 
            return
        }
        try {
            await API.updateAsin('ebay_verified', { epid, asin: asinToAdd })
            setAsin(asinToAdd);  // Update the ASIN state
            setLink(`https://www.amazon.com/dp/${asinToAdd}`);  // Update the link state
            setEditing(false)
        } catch (error) {
            console.error('Error updating ASIN: ', error);
            // Change this to something the user can see.
        }
    }

    if (editing) {
        return (
            <EditForm ref={editFormRef}>
                <form onSubmit={handleSubmit}>
                    <input value={newAsin} onChange={handleChange} />
                </form>
            </EditForm>
        )
    }
    else if (!asin || !link) {
        //console.log('VALUE: ', value, ' ASIN: ', asin, ' LINK: ', link)
        return (
            <div>
                N/A
                {canEdit && <EditButton onClick={() => { setEditing(true) }} />}  {/*Only add edit button if canEdit is true*/}
            </div>
        )

    }
    return (
        <AmazonLinkContainer title={'Amazon'} className={className} isCa={isCa}>
            <AmazonLinkUS href={link} target={'_blank'} isInvalid={isInvalidAsinUS}>
                {asin}
            </AmazonLinkUS>
            {isCa && <AmazonLinkCA title={'Amazon CA'} href={`https://www.amazon.ca/dp/${asin}`} target={'_blank'} isInvalid={isInvalidAsinCA}>
                <Flag code={"CAN"} />
            </AmazonLinkCA>}
            {canEdit && <EditButton onClick={() => { setEditing(true) }} />}
        </AmazonLinkContainer>

    )
}

export default AmazonLink
