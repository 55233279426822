import React from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LockIcon from '@mui/icons-material/Lock';

import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import ChildFriendly from '@mui/icons-material/ChildFriendly';
import TableViewIcon from '@mui/icons-material/TableView';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import TagIcon from "@mui/icons-material/Filter6"
import BookIcon from '@mui/icons-material/Book';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { guid } from "Utils";
import {
    Monitor,
    Leaderboard,
    ManageAccounts,
    Groups,
    SportsScore,
    Timeline,
    ViewList
} from "@mui/icons-material";
import BooksBuffers from "pages/Legacy/BooksBuffers";

const Profile = React.lazy(() => import("pages/profile"))
const CronLogs = React.lazy(() => import("pages/cronLogs"));
const Marketing = React.lazy(() => import("pages/Legacy/Marketing"));
const Users = React.lazy(() => import("pages/users"));
const TopSellers = React.lazy(() => import("pages/topSellers"));
const Feedback = React.lazy(() => import("pages/feedback"))
const Dashboard = React.lazy(() => import("pages/dashboard"))
const SecuredInventory = React.lazy(() => import("pages/Legacy/SecuredInventory"))
const OptiScore = React.lazy(() => import("pages/Legacy/OptiScore"))
const TagsData = React.lazy(() => import("pages/Legacy/Tags"))
const HoldingArea = React.lazy(() => import("pages/Legacy/HoldingArea"))
const Settings = React.lazy(() => import("pages/settings"))
const Testing = React.lazy(() => import("pages/testing"))
const UnsecuredInventoryDocument = React.lazy(() => import("pages/Legacy/UnsecuredInventory"))


const Products = React.lazy(() => import("pages/products"))
const ProductsFinder = React.lazy(() => import("pages/productFinder"))
const Product = React.lazy(() => import("pages/product"))
const ProductsBsrHistory = React.lazy(() => import("pages/ProductsBsrHistory"))

const Orders = React.lazy(() => import("pages/orders/List"))
const RevenueReports = React.lazy(() => import("pages/orders/NetRevenue"))
const OrdersGraph = React.lazy(() => import("pages/orders/ChartReports"))
const Order = React.lazy(() => import("pages/orders/order"))

const AccessAccountPermissions = React.lazy(() => import("pages/AccessControl/AccessAccountPermissions"));
const AccessUserPermissions = React.lazy(() => import("pages/AccessControl/AccessUsersPermissions"));



export enum RoutesPaths {
    home = '/home',
    dashboard = '/dashboard',
    users = '/users',
    monitoringCronLogs = '/monitoring/cron-logs',
    monitoringFeedback = '/monitoring/feedback',
    products = '/products',
    productsFinder = '/product-finder',
    product = '/products/:productId',
    productBsr = '/products-bsr',
    topSellers = '/products/top-sellers',
    ordersHistory = '/orders',
    order = '/orders/:orderId',
    ordersRevenue = '/orders/net-revenue',
    ordersChannels = '/orders/channels',
    legacyUnsecuredInventory = '/google-sheets/unsecured-inventory',
    legacyMarketing = '/google-sheets/marketing',
    legacySecuredInv = '/google-sheets/secured-inventory',
    optiScore = '/google-sheets/opti-score',
    tagsSheet = '/google-sheets/tags',
    holdingArea = '/google-sheets/holding-area',
    buffers_books = '/google-sheets/buffers-books',
    accessControlDashboard = '/access-control',
    settings = '/settings',
    testing = '/testing',
    profile = '/profile',
    accessControlAccountPermissions = '/access-control/account-permissions',
    accessControlUserPermissions = '/access-control/user-permissions',
    accessControlAccountRoute = '/access-control/account-route',
    accessControlUserRoute = '/access-control/user-route',
}

export const routerList = [
    {
        guid: guid(),
        path: RoutesPaths.home,
        noMenu: true,
        component: Profile
    },
    {
        guid: guid(),
        title: 'Dashboard',
        icon: DashboardIcon,
        path: RoutesPaths.dashboard,
        component: Dashboard
    },
    {
        guid: guid(),
        title: 'Users',
        icon: PeopleIcon,
        path: RoutesPaths.users,
        component: Users
    },
    {
        guid: guid(),
        title: 'Products',
        icon: WarehouseIcon,
        path: RoutesPaths.products,
        component: Products,
        /*children: [
             {
                 guid: guid(),
                 title: 'Products',
                 icon: WarehouseIcon,
                 path: RoutesPaths.products,
                 component: Products,
                 noMenu: true
             },
             {
                 guid: guid(),
                 title: 'Top Sellers',
                 disabled: true,
                 icon: EmojiEventsIcon,
                 path: RoutesPaths.topSellers,
                 component: TopSellers
             },
         ]*/
    },
    /*   {
           guid: guid(),
           title: 'BSR',
           icon: Timeline,
           path: RoutesPaths.productBsr,
           component: ProductsBsrHistory
       },*/
    /*    {
            guid: guid(),
            title: 'Orders',
            icon: ReceiptIcon,
            path: RoutesPaths.ordersHistory,
            children: [
                {
                    guid: guid(),
                    title: 'Net Revenue',
                    icon: SsidChartIcon,
                    path: RoutesPaths.ordersRevenue,
                    component: RevenueReports,
                },
                {
                    guid: guid(),
                    title: 'History',
                    icon: ReceiptIcon,
                    path: RoutesPaths.ordersHistory,
                    component: Orders,
                    noMenu: true
                },
                {
                    guid: guid(),
                    title: 'Channels',
                    icon: Leaderboard,
                    path: RoutesPaths.ordersChannels,
                    component: OrdersGraph,
                },
            ]
        },*/
    {
        guid: guid(),
        title: 'Google Sheets',
        icon: TableViewIcon,
        defaultPath: RoutesPaths.optiScore,
        children: [
            {
                guid: guid(),
                title: 'Marketing',
                icon: InsertChartIcon,
                path: RoutesPaths.legacyMarketing,
                component: Marketing
            },
            {
                guid: guid(),
                title: 'Unsecured',
                icon: NoEncryptionIcon,
                path: RoutesPaths.legacyUnsecuredInventory,
                component: UnsecuredInventoryDocument
            },
            {
                guid: guid(),
                title: 'Secured Inventory',
                icon: LockIcon,
                path: RoutesPaths.legacySecuredInv,
                component: SecuredInventory
            },
            {
                guid: guid(),
                title: '365-Products',
                icon: SportsScore,
                path: RoutesPaths.optiScore,
                component: OptiScore
            },
            {
                guid: guid(),
                title: 'Holding Area',
                icon: ViewList,
                path: RoutesPaths.holdingArea,
                component: HoldingArea
            },
            {
                guid: guid(),
                title: 'Tags',
                icon: TagIcon,
                path: RoutesPaths.tagsSheet,
                component: TagsData
            },
            // TO DO: PUT UNSHIPPED TAB HERE
            {
                guid: guid(),
                title: 'Books&Buffers',
                icon: BookIcon,
                path: RoutesPaths.buffers_books,
                component: BooksBuffers
            },
        ]
    },
    {
        guid: guid(),
        noMenu: true,
        path: RoutesPaths.profile,
        component: Profile
    },
    {
        guid: guid(),
        noMenu: true,
        path: RoutesPaths.order,
        component: Order
    },
    {
        guid: guid(),
        noMenu: true,
        path: RoutesPaths.product,
        component: Product
    },
    {
        guid: guid(),
        title: 'Monitoring',
        icon: Monitor,
        children: [
            {
                guid: guid(),
                title: 'Cron Logs',
                icon: AssignmentTurnedInIcon,
                path: RoutesPaths.monitoringCronLogs,
                component: CronLogs
            },
            /* {
                 guid: guid(),
                 title: 'Feedback',
                 icon: FeedbackIcon,
                 path: RoutesPaths.monitoringFeedback,
                 component: Feedback
             },*/
        ]
    },
    {
        guid: guid(),
        title: 'Product Finder',
        icon: SearchIcon,
        path: RoutesPaths.productsFinder,
        component: ProductsFinder
    },
    {
        guid: guid(),
        title: 'Access Control',
        icon: ManageAccounts,
        path: RoutesPaths.accessControlDashboard,
        noRoute: true
    },
    {
        guid: guid(),
        title: 'Settings',
        icon: SettingsIcon,
        path: RoutesPaths.settings,
        component: Settings
    },
    {
        guid: guid(),
        title: 'Testing',
        icon: ChildFriendly,
        path: RoutesPaths.testing,
        component: Testing
    }

]


export const accessControlRoutes = [
    {
        guid: guid(),
        title: 'Access Permissions',
        icon: ManageAccounts,
        children: [
            {
                guid: guid(),
                title: 'Accounts',
                icon: Groups,
                path: RoutesPaths.accessControlAccountPermissions,
                component: AccessAccountPermissions
            },
            {
                guid: guid(),
                title: 'Users',
                icon: PeopleIcon,
                path: RoutesPaths.accessControlUserPermissions,
                component: AccessUserPermissions
            },
        ]
    },
]
